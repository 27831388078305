import { defineStore } from 'pinia'
import { useToast } from '~/modules/ui/components/toast'

interface Notification {
  id: string
  userId: string
  title: string
  icon?: string
  description: string
  timestamp: string
  source: string
  read: boolean
  imageUrl?: string
  link?: string
  markedAsReadTimestamp?: string
  persistent: boolean
  deliveredVia: 'web' | 'email'
}

type SupabaseClientType = ReturnType<typeof useSupabaseClient>

export const useNotificationsStore = defineStore('notifications', () => {
  // --- state ---
  const notifications = ref<Notification[]>([])
  const loading: Ref<boolean> = ref(false)
  const supabase = useSupabaseClient<SupabaseClientType>()
  const error: Ref<string | null> = ref(null)

  // --- getters ---
  const unreadNotifications = computed(() =>
    notifications.value.filter(notification => !notification.read),
  )

  // --- actions ---
  // Fetch the user's notifications based on their auth ID
  async function fetchNotifications(): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data: { user } } = await supabase.auth.getUser()

      if (!user) { throw new Error('User not found') }

      const { data, error: supabaseError } = await supabase
        .from('Notification')
        .select('*')
        .eq('userId', user.id)
        .order('timestamp', { ascending: false })

      if (supabaseError) { throw supabaseError }

      notifications.value = data as Notification
      subscribeToNotificationChanges(user.id)
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching user notifications:', e)
    }
    finally {
      loading.value = false
    }
  }

  // Subscribe to real-time changes
  function subscribeToNotificationChanges(userId: string): void {
    supabase
      .channel(`public:Notification:userId=eq.${userId}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'Notification', filter: `userId=eq.${userId}` },
        (payload) => {
          useLogger().log('Received real-time update:', '#realtime', '#subscribeToNotificationChanges', payload)
          const newNotification = payload.new as Notification
          if (payload.eventType === 'UPDATE') {
            const index = notifications.value.findIndex(n => n.id === newNotification.id)
            if (index !== -1) {
              notifications.value[index] = newNotification
            }
          }
          else if (payload.eventType === 'INSERT') {
            notifications.value.push(newNotification)
            useToast().toast({ title: 'You have a new notification', description: newNotification.title, variant: 'default', duration: 4000 })
          }
        },
      )
      .subscribe()
    useLogger().log('Subscribed to user notifications for real-time updates', '#realtime', '#subscribeToNotificationChanges')
  }

  function addNotification(notification: Notification) {
    notifications.value.push(notification)
  }

  // Mark all notifications as read
  async function markAllAsRead(): Promise<void> {
    loading.value = true
    error.value = null

    try {
      // Update all unread notifications in Supabase
      const { error: supabaseError } = await supabase
        .from('Notification')
        .update({ read: true, markedAsReadTimestamp: new Date().toISOString() })
        .eq('read', false)

      if (supabaseError) {
        throw supabaseError
      }

      // Update the local state
      notifications.value.forEach((notification) => {
        if (!notification.read) {
          notification.read = true
          notification.markedAsReadTimestamp = new Date().toISOString()
        }
      })

      // Show success toast
      useToast().toast({ title: 'All notifications marked as read', variant: 'success', duration: 2000 })
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error marking all notifications as read:', e)
      useToast().toast({ title: 'Failed to mark all notifications as read', variant: 'error', duration: 2000 })
    }
    finally {
      loading.value = false
    }
  }

  // Mark a specific notification as read
  async function markAsRead(notificationId: string): Promise<void> {
    loading.value = true
    error.value = null

    try {
      if (!notificationId) {
        throw new Error('No notification ID!')
      }

      // Update the notification in Supabase
      const { error: supabaseError } = await supabase
        .from('Notification')
        .update({ read: true, markedAsReadTimestamp: new Date().toISOString() })
        .eq('id', notificationId)
        .single()

      if (supabaseError) {
        throw supabaseError
      }

      // Update the local state
      const localNotification = notifications.value.find(n => n.id === notificationId)
      if (localNotification) {
        localNotification.read = true
        localNotification.markedAsReadTimestamp = new Date().toISOString()
      }

      // Show success toast
      useToast().toast({ title: 'Notification marked as read', variant: 'success', duration: 2000 })
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error marking notification as read:', e)
      useToast().toast({ title: 'Failed to mark notification as read', variant: 'error', duration: 2000 })
    }
    finally {
      loading.value = false
    }
  }

  return { fetchNotifications, notifications, unreadNotifications, addNotification, markAllAsRead, markAsRead, notificationsLoading: loading }
})

export type NotificationsStore = ReturnType<typeof useNotificationsStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
