<script lang="ts" setup>
import { Bell, BellRing } from 'lucide-vue-next'

// Initialize the user profile store
const userProfileStore = useUserProfileStore()

// Destructure the store properties using storeToRefs for reactivity
const { profile } = storeToRefs(userProfileStore)

// Initialize the store
const notificationsStore = useNotificationsStore()

// Destructure the store properties using storeToRefs for reactivity
const { unreadNotifications } = storeToRefs(notificationsStore)

// Method to fetch the profile
const fetchNotifications = async () => {
  await notificationsStore.fetchNotifications()
}

// Method to mark all notifications as read
const markAllAsRead = async () => {
  await notificationsStore.markAllAsRead()
}

// Method to mark a notification as read
const markAsRead = async (id: string) => {
  await notificationsStore.markAsRead(id)
}

// Fetch the profile when the component is mounted
onMounted(fetchNotifications)

const unreadNotificationsLength = computed(() => {
  const unreadCount = unreadNotifications?.value?.length || 0
    const profileComplete = profile?.value?.profile_complete ? 0 : 1
    return unreadCount + profileComplete
  })
</script>

<template>
  <Popover>
    <PopoverTrigger as-child>
      <Button variant="ghost" size="icon" class="relative">
        <Bell v-if="!unreadNotificationsLength" class="text-muted-foreground" />
        <BellRing v-if="unreadNotificationsLength" class="text-muted-foreground" />
        <span v-if="unreadNotificationsLength" class="absolute top-1 right-1 block h-2 w-2 rounded-full  bg-primary animate-ping" />
        <span v-if="unreadNotificationsLength" class="absolute text-center block text-xs font-bold text-foreground mb-1">{{ unreadNotificationsLength }}</span>
      </Button>
    </PopoverTrigger>
    <PopoverContent
      class="w-80 bg-card text-card-foreground p-2 mr-4"
      side="bottom"
      :side-offset="10"
    >
      <div class="p-1 space-y-2">
        <div class="grid gap-2">
          <div v-if="!profile?.profile_complete">
            <div class="grid gap-2 relative bg-slgreen-300 text-secondary rounded-md p-2">
              <NuxtLink to="/app/settings/account/general" class="flex items-center  hover:opacity-80">
                <div class="">
                  <p class="font-medium">
                    Complete your Profile
                  </p>
                  <p class="text-sm ">
                    You're not completed your profile. Click here to complete it to get the best experience.
                  </p>
                </div>
              </NuxtLink>
            </div>
          </div>

          <!-- NOTIFICATION -->
          <div v-for="item in unreadNotifications" :key="item.id" class="grid gap-2 relative group">
            <NuxtLink :to="item.link ? item.link : '#'" class="flex gap-3 hover:bg-background">
              <div class="px-2 rounded-full">
                <Avatar class="h-6 w-6">
                  <!-- <AvatarImage src="" alt="" /> -->
                  <AvatarFallback>M</AvatarFallback>
                </Avatar>
              </div>
              <div class="flex-1">
                <p class="font-medium">
                  {{ item.title }}
                </p>
                <p class="text-sm text-muted-foreground">
                  {{ item.description }}
                </p>
              </div>
            </NuxtLink>
            <Button
              v-if="!item.read"
              variant="outline"
              class="border-primary text-primary text-xs"
              @click="markAsRead(item.id)"
            >
              Mark as read
            </Button>
            <Separator />
          </div>

          <!-- END NOTIFICATION -->
        </div>
        <div v-if="unreadNotificationsLength && unreadNotificationsLength > 1" class="bg-secondary-foreground ">
          <Button class="w-full" @click="markAllAsRead()">
            Mark all as read
          </Button>
        </div>
        <div v-if="!unreadNotificationsLength" class="bg-secondary-foreground ">
          <Button variant="link" class="w-full">
            You're all caught up!
          </Button>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>

<style>

</style>
