<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useOrganizationStore } from '~/stores/organizationStore'

const organizationStore = useOrganizationStore()
const { switchOrganization } = organizationStore

const { userOrganizationRoles, currentOrganization } = storeToRefs(organizationStore)

const selectedOrgModel = computed({
  get: () => currentOrganization.value?.id || '',
  set: (orgId: string) => {
    if (orgId !== currentOrganization.value?.id) {
      switchOrganization(orgId)
    }
  },
})

const getOrgRoleName = (orgRole: { role: { name: string } }) => {
  const fullName = orgRole.role.name
    return fullName.startsWith('Org_') ? fullName.slice(4) : fullName
  };
</script>

<template>
  <DropdownMenuRadioGroup v-model="selectedOrgModel">
    <DropdownMenuRadioItem
      v-for="orgRole in userOrganizationRoles"
      :key="orgRole.organization.id"
      :value="orgRole.organization.id"
    >
      <div class="flex flex-col">
        <span>{{ orgRole.organization.name }}</span>
        <span class="text-xs text-gray-500 font-thin">{{ getOrgRoleName(orgRole) }}</span>
      </div>
    </DropdownMenuRadioItem>
  </DropdownMenuRadioGroup>
</template>
