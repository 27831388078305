<script setup lang="ts">
// const supabase = useSupabaseClient()
const user = useSupabaseUser()

// Stores
const userProfileStore = useUserProfileStore()
const organizationStore = useOrganizationStore()
const { profile } = storeToRefs(userProfileStore)

// Method to fetch the profile
const fetchProfile = async () => {
  await userProfileStore.fetchProfile()
}

// Fetch the profile when the component is mounted, this will also start watching the profile.
onMounted(fetchProfile)
</script>

<template>
  <DropdownMenu v-if="user">
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" size="icon">
        <UserAvatar
          :name="profile?.full_name || user.user_metadata?.name || ''"
          :avatar-url="user.user_metadata?.avatar_url || constructFallbackAvatarUrl(profile?.full_name || 'U')"
        />
      </Button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">
      <DropdownMenuLabel>
        {{ profile?.first_name }} {{ profile?.last_name }}
        <span class="block text-xs font-normal opacity-70">{{
          user.email
        }}</span>
        <span v-if="organizationStore?.currentOrganization?.name" class="block text-xs font-semibold opacity-70">{{
          organizationStore.currentOrganization?.name || 'No organization'
        }}</span>
      </DropdownMenuLabel>

      <DropdownMenuSeparator />

      <!-- Color mode selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <ColorModeToggleIcon />
          <div class="ml-2">
            Colour Mode
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <ColorModeToggleRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <!-- Switch Organization selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <Icon name="soilicon:building-05" class="size-4" />
          <div class="ml-2">
            Switch Organisation
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <OrganizationSwitcherRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <NuxtLinkLocale to="/app/settings/account/general">
          <Icon name="soilicon:settings-01" class="mr-2 size-4" />
          Account Settings
        </NuxtLinkLocale>
      </DropdownMenuItem>

      <DropdownMenuItem @click="organizationStore.acceptOrganizationInvites()">
        <Icon name="lucide:refresh-ccw-dot" class="mr-2 size-4" />
        Check Invites
      </DropdownMenuItem>

      <DropdownMenuItem @click="useLogout()">
        <Icon name="soilicon:log-out-01" class="mr-2 size-4" />
        Logout
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
