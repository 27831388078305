<script lang="ts" setup>
import { Mail, Paperclip } from 'lucide-vue-next'

const dayjs = useDayjs()

// Initialize the user conversations store
const userConversationsStore = useUserConversationsStore()

// Destructure the store properties using storeToRefs for reactivity
const { unreadMessages, hasUnreadMessages } = storeToRefs(userConversationsStore)

// Method to fetch any latest unread messages from a conversation
const fetchLatestUserUnreadMessages = async () => {
  await userConversationsStore.fetchLatestUserUnreadMessages()
}

// Fetch the conversations when the component is mounted
onMounted(fetchLatestUserUnreadMessages)
</script>

<template>
  <Popover>
    <PopoverTrigger as-child>
      <Button variant="ghost" size="icon" class="relative">
        <Mail class="text-muted-foreground" />
        <span v-if="hasUnreadMessages" class="absolute top-1 right-1 block h-2 w-2 rounded-full bg-primary" />
      </Button>
    </PopoverTrigger>
    <PopoverContent
      class="w-80 bg-card text-card-foreground p-2 mr-4"
      side="bottom"
      :side-offset="10"
    >
      <div class="space-y-2">
        <div class="grid gap-2">
          <!-- MESSAGE -->
          <div v-for="item in unreadMessages" :key="item.id" class="grid gap-2 relative group">
            <NuxtLink :to="`/app/messages/${item.conversation_id}`" class="flex gap-3 hover:bg-background cursor-pointer p-1 rounded-md">
              <ConversationsAvatar v-if="item.sender_id" :user-id="item?.sender_id" />
              <div class="flex-1">
                <div class="flex items-center gap-2">
                  <p class="font-medium line-clamp-2">
                    {{ item?.message_type === 'attachment' ? 'Attachment' : item?.content }}
                  </p>
                  <Paperclip v-if="item?.message_type === 'attachment'" class="w-4 h-4" />
                </div>
                <p class="text-xs text-muted-foreground">
                  {{ dayjs(item.created_at) }}
                </p>
              </div>
            </NuxtLink>
            <Separator />
          </div>
          <div v-if="!unreadMessages.length" class="text-sm text-center mt-4 border-b pb-4">
            You don't have any unread messages
          </div>
          <!-- END MESSAGE -->
        </div>
        <div class="bg-secondary-foreground">
          <Button variant="link" class="w-full" @click="(() => navigateTo('/app/messages'))">
            View all messages
          </Button>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>
